/* Generated file, please DO NOT edit directly */
/* See jsapp/proto2ts.sh */

/* eslint-disable */
import type { StateEnum } from './constants';
import type {
  Annotation,
  AnnotationKind,
  Form as Form1,
  FormConfig,
  FormOutline,
} from './reform';

export const protobufPackage = 'documents';

export const AnalysisPart = {
  UNKNOWN: 'UNKNOWN',
  METADATA: 'METADATA',
  PAGE_WORDS: 'PAGE_WORDS',
  PAGE_OCR: 'PAGE_OCR',
  THUMBNAIL: 'THUMBNAIL',
  FORM_ASSOCIATION: 'FORM_ASSOCIATION',
  SOURCE_META: 'SOURCE_META',
  SIGNATURES: 'SIGNATURES',
  ZONE_EXTRACT: 'ZONE_EXTRACT',
  FORM_MATCHING: 'FORM_MATCHING',
  ZONE_OCR: 'ZONE_OCR',
} as const;

export type AnalysisPart = typeof AnalysisPart[keyof typeof AnalysisPart];

// Renamed from "FormState" to avoid conflicts with enum FormState
export interface FormStateInterface {}

export const FormStateEnum = {
  DEFAULT: 'DEFAULT',
  LISTING_SIDE: 'LISTING_SIDE',
  BUYER_SIDE: 'BUYER_SIDE',
  /**
   * FILLED_SELLER - FILLED_X has a special
   * meaning in tabbing
   */
  FILLED_SELLER: 'FILLED_SELLER',
  FILLED_SELLER2: 'FILLED_SELLER2',
  FILLED_SELLER3: 'FILLED_SELLER3',
  FILLED_SELLER4: 'FILLED_SELLER4',
  FILLED_LISTING_AGENT: 'FILLED_LISTING_AGENT',
  FILLED_LISTING_AGENT2: 'FILLED_LISTING_AGENT2',
  FILLED_BUYER_AGENT: 'FILLED_BUYER_AGENT',
  FILLED_BUYER_AGENT2: 'FILLED_BUYER_AGENT2',
  FILLED_BUYER: 'FILLED_BUYER',
  FILLED_BUYER2: 'FILLED_BUYER2',
  FILLED_BUYER3: 'FILLED_BUYER3',
  FILLED_BUYER4: 'FILLED_BUYER4',
  FILLED_ASSOCIATE_LISTING_AGENT: 'FILLED_ASSOCIATE_LISTING_AGENT',
  FILLED_ASSOCIATE_BUYER_AGENT: 'FILLED_ASSOCIATE_BUYER_AGENT',
} as const;

export type FormStateEnum = typeof FormStateEnum[keyof typeof FormStateEnum];

export interface SharedRecipient {
  name: string;
  email: string;
}

export interface DocumentShared {
  tokens: string[];
  recipients: SharedRecipient[];
}

export interface Document {
  intId: string;
  privateUrl: string;
  url: string;
  privateExternalUrl: string;
  externalUrl: string;
  isExternal: boolean;
  filename: string;
  pages: DocumentPage[];
  byteSize: number;
  id: string;
  pspdfkitDocumentId: string;
  isPspdfkitDeletedAnnotations: boolean;
  secret: string;
  generationData?: DocumentGenerationData;
  generatedAt: number;
  updatedAt: number;
  createdAt: number;
  analysis?: Analysis;
  isDynamic: boolean;
  shareds: DocumentShared[];
  token: string;
}

export interface DocumentGenerationData {
  kind: DocumentGenerationDataKind;
  filledTdv?: DocumentGenerationDataFilledTdv;
  filledEdv?: DocumentGenerationDataFilledEdv;
  annotatedPdf?: DocumentGenerationDataAnnotatedPdf;
  signingCertificate?: DocumentGenerationDataSigningCertificate;
}

export const DocumentGenerationDataKind = {
  UNKNOWN: 'UNKNOWN',
  FILLED_TDV: 'FILLED_TDV',
  FILLED_EDV: 'FILLED_EDV',
  ANNOTATED_PDF: 'ANNOTATED_PDF',
  SIGNING_CERTIFICATE: 'SIGNING_CERTIFICATE',
} as const;

export type DocumentGenerationDataKind =
  typeof DocumentGenerationDataKind[keyof typeof DocumentGenerationDataKind];

export interface DocumentGenerationDataFilledTdv {
  id: string;
  transId: string;
}

export interface DocumentGenerationDataFilledEdv {
  id: string;
  envId: string;
  sign: boolean;
}

export interface DocumentGenerationDataAnnotatedPdf {
  id: string;
  transId: string;
}

export interface DocumentGenerationDataSigningCertificate {
  envelopeId: string;
  envelopeVers: string;
}

export interface DocumentPage {
  id: string;
  /** [(dbfield).mapping="int_id"]; //doc it is a page of */
  documentId: string;
  pageNumber: number;
  contentUuid: string;
  updatedAt: number;
  createdAt: number;
}

export interface SourceMeta {
  zipform?: SourceMetaZipform;
}

export interface SourceMetaZipform {
  zfDocVer: string;
  zfDocId: string;
}

export interface DocumentTabSet {
  id: string;
  vers: string;
  documentUrl: string;
  documentId: string;
  document?: Document;
  dsEnvelopeId: string;
  dsSub: string;
  tabs: DocumentTabSetTab[];
  updatedAt: number;
  createdAt: number;
}

export interface DocumentTabSetTab {
  kind: DocumentTabSetTabKind;
  signHere?: DocumentTabSetTabSignHere;
  initialHere?: DocumentTabSetTabInitialHere;
  dateSigned?: DocumentTabSetTabDateSigned;
  id: string;
  fieldId: string;
  exportValue?: any;
  optional: boolean;
  pageNumber: number;
  role: string;
  /** uint32 order = 8; */
  fieldPart: number;
  xPosition: number;
  yPosition: number;
  formState: FormStateEnum;
  height: number;
  width: number;
}

export const DocumentTabSetTabKind = {
  UNKNOWN: 'UNKNOWN',
  SIGN_HERE: 'SIGN_HERE',
  INITIAL_HERE: 'INITIAL_HERE',
  DATE_SIGNED: 'DATE_SIGNED',
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  FULL_NAME: 'FULL_NAME',
  CHECKBOX: 'CHECKBOX',
  RADIO: 'RADIO',
} as const;

export type DocumentTabSetTabKind =
  typeof DocumentTabSetTabKind[keyof typeof DocumentTabSetTabKind];

export interface DocumentTabSetTabSignHere {}

export interface DocumentTabSetTabInitialHere {}

export interface DocumentTabSetTabDateSigned {}

export interface FormConfigState {}

export const FormConfigStateEnum = {
  UNKNOWN: 'UNKNOWN',
  DRAFT: 'DRAFT',
  IN_QA: 'IN_QA',
  STAGED: 'STAGED',
  PUBLISHED: 'PUBLISHED',
} as const;

export type FormConfigStateEnum =
  typeof FormConfigStateEnum[keyof typeof FormConfigStateEnum];

export interface FillConfig {
  /** Don't show this ID to the frontend to avoid temptation of fetching it. */
  id: string;
  annotations: Annotation[];
  reformForm?: Form1;
  reformFormPrepared?: Form1;
  formLinks: FillConfigFormLink[];
  formOutline?: FormOutline;
  formConfig?: FormConfig;
}

export interface FillConfigFormLink {
  fieldId: string;
  formSeriesId: string;
}

export interface PsPdfKitDocument {
  pspdfkitFormId: string;
  pspdfkitLayerId: string;
}

export interface SuperFormTemplate {
  id: string;
  superformSeriesId: string;
  title: string;
  shortTitle: string;
  sideAffinity: SuperFormTemplateSideAffinity;
  active: boolean;
  /** ie. The LAOR's version # for the source doc */
  sourceVersion: string;
  /** ie. The CAR's form version for the source doc */
  providerFormVersion: string;
  /** generates to a glide form using the pspdfkit system */
  glideFillable: boolean;
  /** counts as an addendum for the purposes of splitting */
  isSplittingAddendum: boolean;
  tags: string[];
  useLaxMatching: boolean;
  orderedFormSeriesIds: string[];
  formSeries: FormSeries[];
  superformIds: string[];
  libraryUuids: string[];
  libraries: FormLibrary[];
}

export const SuperFormTemplateSideAffinity = {
  UNKNOWN_SIDE: 'UNKNOWN_SIDE',
  SALE: 'SALE',
  PURCHASE: 'PURCHASE',
  BOTH_SIDES: 'BOTH_SIDES',
} as const;

export type SuperFormTemplateSideAffinity =
  typeof SuperFormTemplateSideAffinity[keyof typeof SuperFormTemplateSideAffinity];

export interface SuperFormTemplateFormSeries {
  id: string;
  superformTemplateId: string;
  formSeriesId: string;
  index: number;
}

export interface Form {
  id: string;
  vers: string;
  uuid: string;
  title: string;
  shortTitle: string;
  sideAffinity: FormSideAffinity;
  active: boolean;
  qaActive: boolean;
  isProperty: boolean;
  documentUrl: string;
  /**
   * [
   * (dbfield).mapping="int_id"
   * ];
   */
  documentId: string;
  document?: Document;
  libraryUuids: string[];
  libraries: FormLibrary[];
  tabSetId: string;
  /** zipForm form id */
  zfId: string;
  /** zipForm form version */
  zfVersion: string;
  /** zipForm max form version */
  zfExpireVersion: string;
  /** whether we can use zipForm to fill the form */
  zfFillable: boolean;
  /** unique form which is added to end of doc as addendum by zf */
  zfAddendumFormUuid: string;
  /** counts as an addendum for the purposes of splitting */
  isSplittingAddendum: boolean;
  version: string;
  /** ie. The LAOR's version # for the source doc */
  sourceVersion: string;
  /** ie. The CAR's form version for the source doc */
  providerFormVersion: string;
  seriesId: string;
  seriesUuid: string;
  formSeries?: FormSeries;
  seriesDefaultFormId: string;
  /** generates to a glide form using the pspdfkit system */
  glideFillable: boolean;
  /** DO NOT FETCH by this ID */
  fillConfigId: string;
  fillConfig?: FillConfig;
  subforms: Form[];
  superforms: Form[];
  tags: string[];
  useLaxMatching: boolean;
  /** pspdfkit stuff */
  pspdfkitDocumentId: string;
  datePreparedFieldId: string;
  flowConfigEnabled: boolean;
  formFlowConfigId: string;
  flowConfigState: FormConfigStateEnum;
  ocrTestDocumentIds: string[];
  excludeMatching: boolean;
  isSuperform: boolean;
  state: FormState;
  toaFormId: string;
  toaForm?: Form;
  disableAddendums: boolean;
  updatedAt: number;
  createdAt: number;
  lastActiveAt: number;
  scheduledAt: number;
}

/**
 * Marlo: Not using transactions.Transaction.Side because current dependencies schema would require a big refactor to be able to import transaction.proto here
 * Maybe all common messages/enums should be declared separately to avoid this
 */
export const FormSideAffinity = {
  UNKNOWN_SIDE: 'UNKNOWN_SIDE',
  SALE: 'SALE',
  PURCHASE: 'PURCHASE',
  BOTH_SIDES: 'BOTH_SIDES',
} as const;

export type FormSideAffinity =
  typeof FormSideAffinity[keyof typeof FormSideAffinity];

export const FormState = {
  DRAFT: 'DRAFT',
  IN_QA: 'IN_QA',
  STAGED: 'STAGED',
  LEGACY: 'LEGACY',
  PUBLISHED: 'PUBLISHED',
} as const;

export type FormState = typeof FormState[keyof typeof FormState];

export interface FormSeries {
  id: string;
  uuid: string;
  title: string;
  shortTitle: string;
  forms: Form[];
  defaultFormId: string;
  defaultForm?: Form;
  tags: string[];
  excludeMatching: boolean;
  updatedAt: number;
  createdAt: number;
}

export interface FormLibrary {
  id: string;
  vers: string;
  uuid: string;
  title: string;
  active: boolean;
  /** zipForm form id */
  zfId: string;
  /** zipForm form version */
  zfVersion: string;
  allowNonUnique: boolean;
  shortTitle: string;
  /** Won't appear in library selection show unless entitled */
  private: boolean;
  tags: string[];
  /** Appears in library selection even if not entitled but has watermark. */
  requiresEntitlement: boolean;
  qaActive: boolean;
  toaFormId: string;
  toaForm?: Form;
  sourcedStates: StateEnum[];
  disableAddendums: boolean;
  updatedAt: number;
  createdAt: number;
}

export interface FormLibraryForm {
  id: string;
  formId: string;
  form?: Form;
  libraryId: string;
  library?: FormLibrary;
  /** denorm */
  formUuid: string;
  libraryUuid: string;
  updatedAt: number;
  createdAt: number;
}

export interface FormLibraryEntitlement {
  id: string;
  libraryId: string;
  libraryUuid: string;
  userId: string;
  status: FormLibraryEntitlementEntitlementStatus;
  library?: FormLibrary;
  updatedAt: number;
  createdAt: number;
}

export const FormLibraryEntitlementEntitlementStatus = {
  UNKNOWN: 'UNKNOWN',
  ALLOWED: 'ALLOWED',
  BLOCKED: 'BLOCKED',
} as const;

export type FormLibraryEntitlementEntitlementStatus =
  typeof FormLibraryEntitlementEntitlementStatus[keyof typeof FormLibraryEntitlementEntitlementStatus];

export interface FormSubform {
  id: string;
  formId: string;
  subformId: string;
  subform?: Form;
  index: number;
}

export interface PublishStatus {}

export const PublishStatusEnum = {
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
  ARCHIVED: 'ARCHIVED',
} as const;

export type PublishStatusEnum =
  typeof PublishStatusEnum[keyof typeof PublishStatusEnum];

export interface FormFlowConfig {
  id: string;
  vers: string;
  formId: string;
  form?: Form;
  generationFormsIds: string[];
  /**
   * generation_forms are alternate forms that can be used
   * for generation supporting inter-operability between
   * different generation methods
   */
  generationForms: Form[];
  qaGenerationFormId: string;
  qaGenerationForm?: Form;
  pubStatus: PublishStatusEnum;
  handlerUrl: string;
  label: string;
  tags: string[];
  params?: { [key: string]: any };
  imports: string[];
  updatedAt: number;
  createdAt: number;
}

export interface FormMatch {
  formUuid: string;
  startPage: number;
  endPage: number;
  form?: Form;
  formStates: FormStateEnum[];
  score: number;
  isNearMatch: boolean;
  matchMethod: FormMatchMatchMethod;
}

export const FormMatchMatchMethod = {
  UNKNOWN_MATCH_METHOD: 'UNKNOWN_MATCH_METHOD',
  FORM_ASSOCIATION: 'FORM_ASSOCIATION',
  OCR: 'OCR',
} as const;

export type FormMatchMatchMethod =
  typeof FormMatchMatchMethod[keyof typeof FormMatchMatchMethod];

/** Config to transform data from a source dict e.g. OCR zones to target e.g. transaction package fields. */
export interface DataTransform {
  mappings: DataTransformMapping[];
}

export interface DataTransformMapping {
  inputs: DataTransformMappingField[];
  function: string;
  outputs: DataTransformMappingField[];
}

/** Field: either an input or output of a mapping function. */
export interface DataTransformMappingField {
  /** key in source or target dict */
  key: string;
  /** Used only when testing a mapping config */
  value: string;
  /** map func param name or output name (can omit if only one param) */
  functionField: string;
  shouldOverwrite: boolean;
}

/** The mapping config, data and log from running an OCR test */
export interface OcrTestResult {
  dataTransform?: DataTransform;
  log: string[];
}

export interface Orientation {
  angle: number;
  confidence: number;
}

export interface Box {
  /** These coordinates are normalized from [0, 1.0] */
  xMin: number;
  yMin: number;
  xMax: number;
  yMax: number;
}

export interface TextBox {
  text: string;
  box?: Box;
}

export interface TextLine {
  textBox?: TextBox;
  words: TextBox[];
  /**
   * baseline is polynomial coefficients of the line's baseline relative
   * to the lower left corner of the box.
   */
  baseline: number[];
}

export interface PageAnalysisResult {
  pdf?: PDFPageAnalysis;
  ocr?: OCRAnalysis;
  zones: DocumentZoneResult[];
}

export interface FormAssociation {
  formStates: FormStateEnum[];
  formUuid: string;
  pageNumber: number;
}

export interface PDFPageAnalysis {
  width: number;
  height: number;
  aspect: number;
  rot: number;
  words: TextBox[];
}

export interface OCRAnalysis {
  orientation?: Orientation;
  width: number;
  height: number;
  aspect: number;
  lines: TextLine[];
}

export interface PDFDocumentAnalysis {
  info: { [key: string]: any }[];
  dsTrackingInfoXml: string;
  dsEnvelopeStatusXml: string;
  dsEnvelopeId: string;
  dsDocumentId: string;
  dsEnvelopeStatus: string;
}

export interface DocumentAnalysisResult {
  pdf?: PDFDocumentAnalysis;
  pageCount: number;
  thumbnailUrl: string;
  hexdigest: string;
}

export interface SignatureDetectionResult {
  result: SignatureDetectionResultResult[];
}

export const SignatureDetectionResultClazz = {
  UNKNOWN: 'UNKNOWN',
  SIGNATURE: 'SIGNATURE',
} as const;

export type SignatureDetectionResultClazz =
  typeof SignatureDetectionResultClazz[keyof typeof SignatureDetectionResultClazz];

export const SignatureDetectionResultUserFeedback = {
  /** NOT_PROVIDED - Not named 'UNKNOWN' due to conflict with Clazz.UNKNOWN */
  NOT_PROVIDED: 'NOT_PROVIDED',
  /** NOT_REQUIRED - Signature is not required */
  NOT_REQUIRED: 'NOT_REQUIRED',
  /** IS_PRESENT - Signature is present, */
  IS_PRESENT: 'IS_PRESENT',
} as const;

export type SignatureDetectionResultUserFeedback =
  typeof SignatureDetectionResultUserFeedback[keyof typeof SignatureDetectionResultUserFeedback];

export interface SignatureDetectionResultResult {
  zone?: DocumentZone;
  clazz: SignatureDetectionResultClazz;
  score: number;
  userFeedback: SignatureDetectionResultUserFeedback;
}

export interface AnalysisResponse {
  /** metadata */
  documentResult?: DocumentAnalysisResult;
  thumbnailUrl: string;
  pages: AnalysisResponsePageDocument[];
  analysisVersion: string;
  documentId: string;
  parts: AnalysisPart[];
  sourceMeta?: SourceMeta;
  signatures?: SignatureDetectionResult;
  matchedForms: FormMatch[];
  lazyOcr: boolean;
  attachmentId: string;
  transactionId: string;
}

export interface AnalysisResponsePageDocument {
  result?: PageAnalysisResult;
  pageNumber: number;
  formAssociation?: FormAssociation;
}

export interface PageAnalysis {
  id: string;
  result?: PageAnalysisResult;
  formAssociation?: FormAssociation;
  contentUuid: string;
  analysisVersion: string;
  updatedAt: number;
  createdAt: number;
}

export interface Analysis {
  id: string;
  documentId: string;
  analysisVersion: string;
  sourceMeta?: SourceMeta;
  documentResult?: DocumentAnalysisResult;
  thumbnailUrl: string;
  pages: AnalysisAnalysisPage[];
  parts: AnalysisPart[];
  /** map keys can't be enums */
  stateByPart: { [key: number]: AnalysisAnalysisState };
  signatures?: SignatureDetectionResult;
  sourcedStates: StateEnum[];
  matchedForms: FormMatch[];
  updatedAt: number;
  createdAt: number;
}

export const AnalysisAnalysisStatus = {
  UNKNOWN: 'UNKNOWN',
  /** PENDING - analysis in progress */
  PENDING: 'PENDING',
  /** FAILED - known failure of analysis */
  FAILED: 'FAILED',
  /** PENDING_REANALYSIS - analyzed successfully and doing it again */
  PENDING_REANALYSIS: 'PENDING_REANALYSIS',
  /** FAILED_REANALYSIS - analyzed successfully before but the last attempt failed */
  FAILED_REANALYSIS: 'FAILED_REANALYSIS',
  /** COMPLETE - last attempt succeeded */
  COMPLETE: 'COMPLETE',
  /** SKIPPED - application has decided not to do this analyisis part for this document */
  SKIPPED: 'SKIPPED',
  /** PENDING_LAZY_ANALYSIS - not analyzed and to do this analyisis part in the future */
  PENDING_LAZY_ANALYSIS: 'PENDING_LAZY_ANALYSIS',
  /** COMPLETE_LAZY_ANALYSIS - analyzed successfully and need to do remain analyisis parts in the future */
  COMPLETE_LAZY_ANALYSIS: 'COMPLETE_LAZY_ANALYSIS',
} as const;

export type AnalysisAnalysisStatus =
  typeof AnalysisAnalysisStatus[keyof typeof AnalysisAnalysisStatus];

export interface AnalysisAnalysisPage {
  analysis?: PageAnalysis;
  pageNumber: number;
}

export interface AnalysisAnalysisState {
  status: AnalysisAnalysisStatus;
  attemptedAt: number;
  finishedAt: number;
}

export interface AnalysisStateByPartEntry {
  key: number;
  value?: AnalysisAnalysisState;
}

export interface DocumentRecombinationRequest {
  newDocuments: DocumentRecombinationRequestNewDocument[];
  skipAnalysis: boolean;
}

/** merge, split, and recombine */
export interface DocumentRecombinationRequestNewDocument {
  filename: string;
  sourceRanges: DocumentRecombinationRequestNewDocumentSourceRange[];
}

export interface DocumentRecombinationRequestNewDocumentSourceRange {
  startPage: number;
  endPage: number;
  documentId: string;
  isFullDocument: boolean;
  documentUrl: string;
  rotation: number;
}

export interface FormFixture {
  libraries: FormLibrary[];
  forms: Form[];
  associations: FormLibraryForm[];
  formSeries: FormSeries[];
}

export interface FormFixtureLoadResult {
  formsUpdated: Form[];
  formsCreated: Form[];
  librariesUpdated: FormLibrary[];
  librariesCreated: FormLibrary[];
  documentsCreated: Document[];
}

export interface PageAssociationResponse {
  documentId: string;
  pages: PageAssociationResponsePage[];
}

export interface PageAssociationResponseMatch {
  transactionDocumentId: string;
  pageNumber: number;
  transactionId: string;
}

export interface PageAssociationResponsePage {
  pageNumber: number;
  matches: PageAssociationResponseMatch[];
}

export interface DocumentSplitSuggestionResponse {
  splits: DocumentSplitSuggestionResponseSplit[];
  transactionId: string;
}

export interface DocumentSplitSuggestionResponseSplit {
  startPage: number;
  endPage: number;
  taskId: string;
  filename: string;
  formId: string;
  formSeriesId: string;
  formTags: string[];
}

export interface SkewMapRequest {
  /**
   * specifies a source page and target page
   * and a position on the source page
   */
  sourceDocumentId: string;
  sourcePageNumber: number;
  targetDocumentId: string;
  targetPageNumber: number;
  sourceXPosition: number;
  sourceYPosition: number;
}

export interface TabMatch {
  tab?: DocumentTabSetTab;
  offset: number;
  sourceDocumentId: string;
  matchMethod: FormMatchMatchMethod;
}

export interface SkewMapResponse {
  targetXPosition: number;
  targetYPosition: number;
  outcome: SkewMapResponseOutcome;
}

/**
 * specifies a position on the target page
 * and how it the mapping was made
 */
export const SkewMapResponseOutcome = {
  UNKNOWN: 'UNKNOWN',
  MISSING_ANALYSIS: 'MISSING_ANALYSIS',
  RESCALE_ONLY: 'RESCALE_ONLY',
  MAPPED_OFF_PAGE: 'MAPPED_OFF_PAGE',
  MAPPED: 'MAPPED',
} as const;

export type SkewMapResponseOutcome =
  typeof SkewMapResponseOutcome[keyof typeof SkewMapResponseOutcome];

export interface FormFill {
  formId: string;
  form?: Form;
  formSeriesId: string;
  formSeries?: Form;
  fieldValues?: { [key: string]: any };
  formStates: FormStateEnum[];
  unlinkedFieldIds: string[];
  formOutlineFlowId: string;
}

export interface DocumentZone {
  id: string;
  annotationId: string;
  name: string;
  kind: AnnotationKind;
  formId: string;
  vertices: DocumentZoneVertex[];
  originalLocation?: DocumentZoneLocation;
  page: number;
}

export interface DocumentZoneLocation {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}

export interface DocumentZoneVertex {
  x: number;
  y: number;
}

export interface DocumentZoneResult {
  zone?: DocumentZone;
  ocrText: string;
  textZone?: DocumentZoneResultTextZone;
  dateZone?: DocumentZoneResultDateZone;
  checkboxZone?: DocumentZoneResultCheckboxZone;
  signatureZone?: DocumentZoneResultSignatureZone;
}

export interface DocumentZoneResultTextZone {}

export interface DocumentZoneResultDateZone {
  date: number;
}

export interface DocumentZoneResultCheckboxZone {
  zoneUrl: string;
}

export interface DocumentZoneResultSignatureZone {}

export interface FormRenderData {
  fieldValues?: { [key: string]: any };
  overflowParagraphs: number;
  document?: Document;
}

export interface FormRenderAudit {
  id: string;
  transId: string;
  tdvId: string;
  flowId: string;
  formFill?: FormFill;
  cleanedData?: { [key: string]: any };
  updatedAt: number;
  createdAt: number;
}

export interface FormDocumentUpload {
  url: string;
  byteSize: number;
  filename: string;
  thumbnailUrl: string;
}

export interface CreateFormLibrary {
  library?: FormLibrary;
  uploads: FormDocumentUpload[];
}

export interface FormLibraryBulkUpload {
  uploads: FormDocumentUpload[];
}

export interface Tag {
  id: string;
  name: string;
  scope: TagTagScope;
  description: string;
  color: string;
  vers: string;
  updatedAt: number;
  createdAt: number;
}

export const TagTagScope = {
  LIBRARY: 'LIBRARY',
  FORM: 'FORM',
  FLOW: 'FLOW',
} as const;

export type TagTagScope = typeof TagTagScope[keyof typeof TagTagScope];

export interface FormCloneRequest {
  /** the base version for the new form */
  version: string;
  /** the title for the new form */
  title: string;
  /** the series id to contain the new form (a new one will be created if empty) */
  seriesId: string;
  /** if the fill configs should be included in the new form */
  keepFillConfig: boolean;
  /** if the tags should be included in the new form */
  keepTags: boolean;
  /** if the form outline should be included in the new form */
  keepFormOutline: boolean;
  /** if the new form should be included in the same libraries as the original */
  addToLibraries: boolean;
  state: FormState;
}

export interface FormScheduleRequest {
  active: boolean;
  scheduledAt: number;
}

export interface FormLibraryBulkScheduleRequest {
  scheduledAt: number;
}

export interface FormLibraryBulkPublishResponse {
  errors: FormLibraryBulkPublishResponseError[];
  published: Form[];
  scheduled: Form[];
}

export interface FormLibraryBulkPublishResponseError {
  message: string;
  trace: string;
  form?: Form;
}
