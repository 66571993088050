import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import type { SnakeCasedProperties } from 'type-fest';
import type { User, UserConfig } from 'src/types/proto/auth';
import type { InviteRequest } from 'src/types/proto/invites';
import type {
  UpdateUserProfileInfoRequest,
  GetInvitesRequest,
  GetInvitesResponse,
} from 'src/types/proto/services/auth_admin_service';
import type {
  GetUserListRequest,
  GetUserListResponse,
  GetUserRequest,
  FetchCarApiResponseResponse,
  UpdateAdminStatusRequest,
  UpdateUserAccessModesRequest,
  UpdateUserAccountStateRequest,
  UpdateBillingCustomerIdRequest,
  UpdateUserNarApiCredentialsRequest,
  UpdateDefaultTeamRequest,
  UpdateUserEmailRequest,
} from 'src/types/proto/services/user_admin_service';
import type { ApiRequestBody, DeepPartial } from 'src/types/utils';
import Auth from '../public/auth';

export default class AdminAuth extends Auth {
  users(args: GetUserListRequest) {
    const params = omitBy(args, isNil);
    return this.get<GetUserListResponse>('/auth/users', {
      params,
    });
  }

  getUser(userId: string, resolve?: GetUserRequest['resolve']) {
    return this.get<User>(`/auth/users/${encodeURIComponent(userId)}`, {
      params:
        resolve && resolve.length
          ? {
              resolve,
            }
          : null,
    });
  }

  fetchCarApiResponse(nrdsId: string, lastName: string) {
    return this.get<SnakeCasedProperties<FetchCarApiResponseResponse>>(
      `auth/users/fetch_car_response/${encodeURIComponent(
        nrdsId
      )}/${encodeURIComponent(lastName)}`
    );
  }

  updateUserEmail(userId: string, email: string) {
    return this.post<void>('auth/users/update_email', {
      userId,
      email,
    } as UpdateUserEmailRequest);
  }

  updateConfig(userId: string, data: DeepPartial<UserConfig>) {
    return this.post<UserConfig>(`/auth/${userId}/config`, data);
  }

  createInvite(invite: DeepPartial<InviteRequest>) {
    return this.post<InviteRequest>('/auth/invites', invite);
  }

  invites(args: GetInvitesRequest) {
    const params = omitBy(args, isNil);
    return this.get<GetInvitesResponse>('/auth/invites', {
      params,
    });
  }

  getInviteById(inviteId: string) {
    return this.get<InviteRequest>(
      `/auth/invites/${encodeURIComponent(inviteId)}/invite_by_id`
    );
  }

  resendInviteEmail(inviteId: string) {
    return this.get<void>(
      `/auth/invites/${encodeURIComponent(inviteId)}/resend_invite_email`
    );
  }

  updateAdminStatus(
    userId: string,
    isAdmin: ApiRequestBody<UpdateAdminStatusRequest>['isAdmin'],
    appRole: ApiRequestBody<UpdateAdminStatusRequest>['appRole']
  ) {
    return this.post<void>(`auth/users/${userId}/update_admin_status`, {
      appRole,
      isAdmin,
    } as ApiRequestBody<UpdateAdminStatusRequest>);
  }

  updateAccessModes(
    userId: string,
    accessModes: ApiRequestBody<UpdateUserAccessModesRequest>['accessModes']
  ) {
    return this.post<void>(`auth/users/${userId}/access_modes`, {
      accessModes,
    } as ApiRequestBody<UpdateUserAccessModesRequest>);
  }

  updateAccountState(
    userId: string,
    accountState: ApiRequestBody<UpdateUserAccountStateRequest>['accountState']
  ) {
    return this.post<void>(`auth/users/${userId}/account_state`, {
      accountState,
    } as ApiRequestBody<UpdateUserAccountStateRequest>);
  }

  updateNrdsCredentials(
    userId: string,
    narNrdsId: string,
    narLastName: string
  ) {
    return this.post<void>(`auth/users/${userId}/nar_api_credentials`, {
      narNrdsId,
      narLastName,
    } as ApiRequestBody<UpdateUserNarApiCredentialsRequest>);
  }

  updateDefaultTeam(userId: string, teamId: string) {
    return this.post<void>(`auth/users/${userId}/default_team`, {
      teamId,
    } as ApiRequestBody<UpdateDefaultTeamRequest>);
  }

  updateUserProfileInfo(userId: string, avatarUrl: string) {
    return this.post<User>(`auth/users/${userId}/update_user_profile_info`, {
      avatarUrl,
    } as ApiRequestBody<UpdateUserProfileInfoRequest>);
  }

  updateBillingCustomerId(
    userId: string,
    billingCustomerId: ApiRequestBody<UpdateBillingCustomerIdRequest>['billingCustomerId']
  ) {
    return this.post<User>(`auth/users/${userId}/billing_customer_id`, {
      billingCustomerId: billingCustomerId || '',
    } as ApiRequestBody<UpdateBillingCustomerIdRequest>);
  }

  deactivateUser(userId: string) {
    return this.post<void>(`auth/users/${userId}/deactivate`);
  }

  migrateTc(userId: string) {
    return this.post<boolean>(`auth/users/${userId}/migrate_tc`);
  }

  updateIsCompassAgent(userId: string, isCompassAgent: boolean) {
    return this.post<boolean>(`auth/users/${userId}/update_is_compass_agent`, {
      isCompassAgent: isCompassAgent,
    });
  }

  isCompassOrgAdmin() {
    return this.get<boolean>(`auth/users/user_is_compass_admin`);
  }

  mergeAccount(finalUserId: string, dupeUserId: string) {
    return this.post<void>(
      `auth/merge_account/${finalUserId}/merge_with/${dupeUserId}/`
    );
  }
}
